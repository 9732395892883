export const SUBJECT_SMK = [
  "Teknika Kapal Penangkapan Ikan Kelas X",
  "Teknik Perawatan Gedung Kelas XI",
  "Teknik Perawatan Gedung Kelas XII",
  "Konstruksi Jalan, Irigasi, dan Jembatan Kelas XI",
  "Konstruksi Jalan, Irigasi, dan Jembatan Kelas XII",
  "Konstruksi Jalan dan Jembatan Kelas XI",
  "Konstruksi Jalan dan Jembatan Kelas XII",
  "Konstruksi Gedung dan Sanitasi Kelas XI",
  "Konstruksi Gedung dan Sanitasi Kelas XII",
  "Desain Interior dan Teknik Furnitur Kelas XI",
  "Desain Interior dan Teknik Furnitur Kelas XII",
  "Desain dan Teknik Furnitur Kelas XI",
  "Desain dan Teknik Furnitur Kelas XII",
  "Teknik Mekanik Industri Kelas XI",
  "Teknik Mekanik Industri Kelas XII",
  "Teknik Pengecoran Logam Kelas XI",
  "Teknik Pengecoran Logam Kelas XII",
  "Desain Gambar Mesin Kelas XI",
  "Desain Gambar Mesin Kelas XII",
  "Teknik Pemesinan Pesawat Udara (Aircraft Machining) Kelas XI",
  "Teknik Pemesinan Pesawat Udara (Aircraft Machining) Kelas XII",
  "Teknik Konstruksi Rangka Pesawat Udara (Airframe Mechanic) Kelas XI",
  "Teknik Konstruksi Rangka Pesawat Udara (Airframe Mechanic) Kelas XII",
  "Teknik Pemesinan Kapal Kelas XI",
  "Teknik Pemesinan Kapal Kelas XII",
  "Teknik Alat Berat Kelas XI",
  "Teknik Alat Berat Kelas XII",
  "Teknik Ototronik Kelas XI",
  "Teknik Ototronik Kelas XII",
  "Teknik Bodi Kendaraan Ringan Kelas XI",
  "Teknik Bodi Kendaraan Ringan Kelas XII",
  "Teknik Pengelasan Kelas XI",
  "Teknik Pengelasan Kelas XII",
  "Teknik Pengelasan Kapal Kelas XI",
  "Teknik Pengelasan Kapal Kelas XII",
  "Teknik Konstruksi Badan Pesawat Udara (Aircraft Sheet Metal Forming) Kelas XI",
  "Teknik Konstruksi Badan Pesawat Udara (Aircraft Sheet Metal Forming) Kelas XII",
  "Teknik Fabrikasi Logam dan Manufaktur Kelas XI",
  "Teknik Fabrikasi Logam dan Manufaktur Kelas XII",
  "Teknik Pengendalian Produksi Kelas XI",
  "Teknik Pengendalian Produksi Kelas XII",
  "Teknik Logistik Kelas XI",
  "Teknik Logistik Kelas XII",
  "Teknik Audio Video Kelas XI",
  "Teknik Audio Video Kelas XII",
  "Teknik Mekatronika Kelas XI",
  "Teknik Mekatronika Kelas XII",
  "Teknik Otomasi Industri Kelas XI",
  "Teknik Otomasi Industri Kelas XII",
  "Teknik Elektronika Komunikasi Kelas XI",
  "Teknik Elektronika Komunikasi Kelas XII",
  "Instrumentasi Medik Kelas XI",
  "Instrumentasi Medik Kelas XII",
  "Teknik Elektronika Pesawat Udara (Aviation Electronics) Kelas XI",
  "Teknik Elektronika Pesawat Udara (Aviation Electronics) Kelas XII",
  "Instrumentasi dan Otomatisasi Proses Kelas XI",
  "Instrumentasi dan Otomatisasi Proses Kelas XII",
  "Airframe Powerplant Kelas XI",
  "Airframe Powerplant Kelas XII",
  "Electrical Avionic Kelas XI",
  "Electrical Avionic Kelas XII",
  "Desain Rancang Bangun Kapal Kelas XI",
  "Desain Rancang Bangun Kapal Kelas XII",
  "Konstruksi Kapal Baja Kelas XI",
  "Konstruksi Kapal Baja Kelas XII",
  "Konstruksi Kapal Non Baja Kelas XI",
  "Konstruksi Kapal Non Baja Kelas XII",
  "Interior Kapal Kelas XI",
  "Interior Kapal Kelas XII",
  "Analisis Pengujian Laboratorium Kelas XI",
  "Analisis Pengujian Laboratorium Kelas XII",
  "Teknik Kimia Industri Kelas XI",
  "Teknik Kimia Industri Kelas XII",
  "Kimia Tekstil Kelas XI",
  "Kimia Tekstil Kelas XII",
  "Teknik Pembuatan Serat Filamen Kelas XI",
  "Teknik Pembuatan Serat Filamen Kelas XII",
  "Teknik Pembuatan Benang Stapel Kelas XI",
  "Teknik Pembuatan Benang Stapel Kelas XII",
  "Teknik Pembuatan Kain Kelas XI",
  "Teknik Pembuatan Kain Kelas XII",
  "Teknik Penyempurnaan Tekstil Kelas XI",
  "Teknik Penyempurnaan Tekstil Kelas XII",
  "Teknik Pembangkit Tenaga Listrik Kelas XI",
  "Teknik Pembangkit Tenaga Listrik Kelas XII",
  "Teknik Pemanasan, Tata Udara, dan Pendinginan (Heating, Ventilation, and Air Conditioning) Kelas XI",
  "Teknik Pemanasan, Tata Udara, dan Pendinginan (Heating, Ventilation, and Air Conditioning) Kelas XII",
  "Teknik Kelistrikan Pesawat Udara (Aircraft Electricity) Kelas XI",
  "Teknik Kelistrikan Pesawat Udara (Aircraft Electricity) Kelas XII",
  "Teknik Kelistrikan Kapal Kelas XI",
  "Teknik Kelistrikan Kapal Kelas XII",
  "Teknik Energi Surya, Hidro, dan Angin Kelas XI",
  "Teknik Energi Surya, Hidro, dan Angin Kelas XII",
  "Teknik Energi Biomassa Kelas XI",
  "Teknik Energi Biomassa Kelas XII",
  "Teknik Geomatika Kelas XI",
  "Teknik Geomatika Kelas XII",
  "Informasi Geospasial Kelas XI",
  "Informasi Geospasial Kelas XII",
  "Geologi Pertambangan Kelas XI",
  "Geologi Pertambangan Kelas XII",
  "Teknik Produksi Minyak dan Gas Kelas XI",
  "Teknik Produksi Minyak dan Gas Kelas XII",
  "Teknik Pemboran Minyak dan Gas Kelas XI",
  "Teknik Pemboran Minyak dan Gas Kelas XII",
  "Teknik Pengolahan Minyak, Gas dan Petrokimia Kelas XI",
  "Teknik Pengolahan Minyak, Gas dan Petrokimia Kelas XII",
  "Pengembangan Gim Kelas XI",
  "Pengembangan Gim Kelas XII",
  "Sistem Informasi, Jaringan, dan Aplikasi Kelas XI",
  "Sistem Informasi, Jaringan, dan Aplikasi Kelas XII",
  "Teknik Jaringan Akses Telekomunikasi Kelas XI",
  "Teknik Jaringan Akses Telekomunikasi Kelas XII",
  "Teknik Transmisi Telekomunikasi Kelas XI",
  "Teknik Transmisi Telekomunikasi Kelas XII",
  "Asisten Dental Kelas XI",
  "Asisten Dental Kelas XII",
  "Asisten Teknik Laboratorium Medik Kelas XI",
  "Asisten Teknik Laboratorium Medik Kelas XII",
  "Farmasi Klinis dan Komunitas Kelas XI",
  "Farmasi Klinis dan Komunitas Kelas XII",
  "Farmasi Industri Kelas XI",
  "Farmasi Industri Kelas XII",
  "Pekerja Sosial Kelas XI",
  "Pekerja Sosial Kelas XII",
  "Agribisnis Perbenihan Tanaman Kelas XI",
  "Agribisnis Perbenihan Tanaman Kelas XII",
  "Agribisnis Ternak Unggas Kelas XI",
  "Agribisnis Ternak Unggas Kelas XII",
  "Kesehatan Hewan Kelas XI",
  "Kesehatan Hewan Kelas XII",
  "Agrbisnis Ikan Hias Kelas XI",
  "Agrbisnis Ikan Hias Kelas XII",
  "Agrbisnis Perikanan Payau dan Laut Kelas XI",
  "Agrbisnis Perikanan Payau dan Laut Kelas XII",
  "Agribisnis Rumput Laut Kelas XI",
  "Agribisnis Rumput Laut Kelas XII",
  "Usaha Pertanian Terpadu Kelas XI",
  "Usaha Pertanian Terpadu Kelas XII",
  "Mekanisasi Pertanian Kelas XI",
  "Mekanisasi Pertanian Kelas XII",
  "Agribisnis Pengolahan Hasil Perikanan Kelas XI",
  "Agribisnis Pengolahan Hasil Perikanan Kelas XII",
  "Pengawasan Mutu Hasil Pertanian Kelas XI",
  "Pengawasan Mutu Hasil Pertanian Kelas XII",
  "Kehutanan Kelas XI",
  "Kehutanan Kelas XII",
  "Teknika Kapal Penangkapan Ikan Kelas XI",
  "Teknika Kapal Penangkapan Ikan Kelas XII",
  "Nautika Kapal Penangkapan Ikan Kelas XI",
  "Nautika Kapal Penangkapan Ikan Kelas XII",
  "Teknik Kapal Niaga Kelas XI",
  "Teknik Kapal Niaga Kelas XII",
  "Nautika Kapal Niaga Kelas XI",
  "Nautika Kapal Niaga Kelas XII",
  "Manajemen Perkantoran Kelas XI",
  "Manajemen Perkantoran Kelas XII",
  "Layanan Perbankan Kelas XI",
  "Layanan Perbankan Kelas XII",
  "Layanan Perbankan Syariah Kelas XI",
  "Layanan Perbankan Syariah Kelas XII",
  "Ekowisata Kelas XI",
  "Ekowisata Kelas XII",
  "Perhotelan Kelas XI",
  "Perhotelan Kelas XII",
  "Kuliner Kelas XI",
  "Kuliner Kelas XII",
  "Spa dan Beauty Therapy Kelas XI",
  "Spa dan Beauty Therapy Kelas XII",
  "Seni Lukis Kelas XI",
  "Seni Lukis Kelas XII",
  "Seni Patung Kelas XI",
  "Seni Patung Kelas XII",
  "Teknik Grafika Kelas XI",
  "Teknik Grafika Kelas XII",
  "Kriya Kreatif Batik dan Tekstil Kelas XI",
  "Kriya Kreatif Batik dan Tekstil Kelas XII",
  "Kriya Kreatif Kulit dan Imitasi Kelas XI",
  "Kriya Kreatif Kulit dan Imitasi Kelas XII",
  "Kriya Kreatif Keramik Kelas XI",
  "Kriya Kreatif Keramik Kelas XII",
  "Kriya Kreatif Logam dan Perhiasan Kelas XI",
  "Kriya Kreatif Logam dan Perhiasan Kelas XII",
  "Kriya Kreatif Kayu dan Rotan Kelas XI",
  "Kriya Kreatif Kayu dan Rotan Kelas XII",
  "Seni Musik Kelas XI",
  "Seni Musik Kelas XII",
  "Seni Tari Kelas XI",
  "Seni Tari Kelas XII",
  "Seni Karawitan Kelas XI",
  "Seni Karawitan Kelas XII",
  "Seni Pendalangan Kelas XI",
  "Seni Pendalangan Kelas XII",
  "Seni Teater Kelas XI",
  "Seni Teater Kelas XII",
  "Tata Artistik Teater Kelas XI",
  "Tata Artistik Teater Kelas XII",
  "Produksi dan Siaran Program Radio Kelas XI",
  "Produksi dan Siaran Program Radio Kelas XII",
  "Produksi dan Siaran Program Televisi Kelas XI",
  "Produksi dan Siaran Program Televisi Kelas XII",
  "Produksi Film Kelas XI",
  "Produksi Film Kelas XII",
  "Animasi Kelas XI",
  "Animasi Kelas XII",
  "Desain Pemodelan dan Informasi Bangunan Kelas XI",
  "Desain Pemodelan dan Informasi Bangunan Kelas XII",
  "Teknik Elektronika Industri Kelas XI",
  "Teknik Elektronika Industri Kelas XII",
  "Kimia Analisis Kelas XI",
  "Kimia Analisis Kelas XII",
  "Rekayasa Perangkat Lunak Kelas XI",
  "Rekayasa Perangkat Lunak Kelas XII",
  "Teknik Komputer dan Jaringan Kelas XI",
  "Teknik Komputer dan Jaringan Kelas XII",
  "Asisten Keperawatan dan Caregiver Kelas XI",
  "Asisten Keperawatan dan Caregiver Kelas XII",
  "Agribisnis Ternak Ruminansia Kelas XI",
  "Agribisnis Ternak Ruminansia Kelas XII",
  "Agribisnis Perikanan Air Tawar Kelas XI",
  "Agribisnis Perikanan Air Tawar Kelas XII",
  "Agribisnis Pengolahan Hasil Pertanian Kelas XI",
  "Agribisnis Pengolahan Hasil Pertanian Kelas XII",
  "Tata Kecantikan Kulit dan Rambut Kelas XI",
  "Tata Kecantikan Kulit dan Rambut Kelas XII",
  "Teknik Konstruksi dan Perumahan Kelas XI",
  "Teknik Konstruksi dan Perumahan Kelas XII",
  "Teknik Pemesinan Kelas XI",
  "Teknik Pemesinan Kelas XII",
  "Teknik Kendaraan Ringan Kelas XI",
  "Teknik Kendaraan Ringan Kelas XII",
  "Teknik Sepeda Motor Kelas XI",
  "Teknik Sepeda Motor Kelas XII",
  "Teknik Instalasi Tenaga Listrik Kelas XI",
  "Teknik Instalasi Tenaga Listrik Kelas XII",
  "Teknik Jaringan Tenaga Listrik Kelas XI",
  "Teknik Jaringan Tenaga Listrik Kelas XII",
  "Agribisnis Tanaman Perkebunan Kelas XI",
  "Agribisnis Tanaman Perkebunan Kelas XII",
  "Agribisnis Tanaman Pangan dan Hortikultura Kelas XI",
  "Agribisnis Tanaman Pangan dan Hortikultura Kelas XII",
  "Agrbisnis Lanskap dan Pertamanan Kelas XI",
  "Agrbisnis Lanskap dan Pertamanan Kelas XII",
  "Bisnis Digital Kelas XI",
  "Bisnis Digital Kelas XII",
  "Bisnis Retail Kelas XI",
  "Bisnis Retail Kelas XII",
  "Manajemen Logistik Kelas XI",
  "Manajemen Logistik Kelas XII",
  "Akuntansi Kelas XI",
  "Akuntansi Kelas XII",
  "Usaha Layanan Wisata Kelas XI",
  "Usaha Layanan Wisata Kelas XII",
  "Desain Komunikasi Visual Kelas XI",
  "Desain Komunikasi Visual Kelas XII",
  "Desain dan Produksi Busana Kelas XI",
  "Desain dan Produksi Busana Kelas XII"
];

export const SUBJECT_BTP = [
  "Bahasa Indonesia Kelas III",
  "Bahasa Indonesia Kelas VI",
  "Bahasa Indonesia Kelas IX",
  "Bahasa Indonesia Kelas XII",
  "Bahasa Indonesia Tingkat Lanjut Kelas XII",
  "Bahasa Inggris Kelas III",
  "Bahasa Inggris Kelas VI",
  "Bahasa Inggris Kelas IX",
  "Bahasa Inggris Kelas XII",
  "Bahasa Inggris Tingkat Lanjut Kelas XII",
  "Ilmu Pengetahuan Alam Dan Sosial Kelas III",
  "Ilmu Pengetahuan Alam Dan Sosial Kelas VI",
  "Ilmu Pengetahuan Alam Kelas IX",
  "Ilmu Pengetahuan Sosial Kelas IX",
  "Sosiologi Kelas XII",
  "Antropologi Kelas XII",
  "Geografi Kelas XII",
  "Ekonomi Kelas XII",
  "Sejarah Kelas XII",
  "Fisika Kelas XII",
  "Kimia Kelas XII",
  "Biologi Kelas XII",
  "Matematika Kelas III",
  "Matematika Kelas VI",
  "Matematika Kelas IX",
  "Matematika Kelas XII",
  "Matematika Tingkat Lanjut Kelas XII",
  "Pendidikan Pancasila Kelas III",
  "Pendidikan Pancasila Kelas VI",
  "Pendidikan Pancasila Kelas IX",
  "Pendidikan Pancasila Kelas XII"
]
